import { template as template_05932e24f81f4ecfab53e6684f0b44e0 } from "@ember/template-compiler";
const FKText = template_05932e24f81f4ecfab53e6684f0b44e0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
