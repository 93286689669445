import { template as template_b9b396e8def54c8fb23ea6856b0044fb } from "@ember/template-compiler";
const SidebarSectionMessage = template_b9b396e8def54c8fb23ea6856b0044fb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
