import { template as template_1569e136852f45eb8ed006891cc2a474 } from "@ember/template-compiler";
const WelcomeHeader = template_1569e136852f45eb8ed006891cc2a474(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
