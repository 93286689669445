import { template as template_80c4ad2eb6544fa1a8042e0ea159e4da } from "@ember/template-compiler";
const FKControlMenuContainer = template_80c4ad2eb6544fa1a8042e0ea159e4da(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
