import { template as template_e7910fd6bb80474ab8616f68ac5728e2 } from "@ember/template-compiler";
const FKLabel = template_e7910fd6bb80474ab8616f68ac5728e2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
